<!--数据-->
<template>
  <div class="container data-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize">城市矿产数据</h1>
      </div>
    </div>
    <el-row>
      <b-card class="col-ms-12 filter" bg-variant="light">
        <b-form-group label="固废来源" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.sources" multiple collapse-tags
                     placeholder="请选择" @change="queryWasteBigSelect">
            <el-option v-for="item in options.wasteSource" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="固废大类" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.wasteBigTypes" multiple collapse-tags
                     placeholder="请选择" @change="queryWasteSmallSelect">
            <el-option v-for="item in options.wasteBigType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="固废小类" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.wasteSmallTypes" multiple collapse-tags
                     placeholder="请选择">
            <el-option v-for="item in options.wasteSmallType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="区域" label-cols-sm="4" label-align-sm="right">
          <el-cascader v-model="searchModel.regions" :options="options.area"
                       :props="{ multiple: true, checkStrictly: true, emitPath: false }" clearable
                       collapse-tags></el-cascader>
        </b-form-group>
        <b-form-group label="年份" label-cols-sm="4" label-align-sm="right">
          <el-select v-model="searchModel.years" multiple collapse-tags
                     placeholder="请选择">
            <el-option v-for="item in options.years" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group label="模糊查询" label-cols-sm="4" label-align-sm="right">
          <el-input placeholder="请输入内容" v-model="searchModel.searchValue" clearable>
          </el-input>
        </b-form-group>
        <div class="search el-col-md-24">
          <el-button type="primary" @click="selectData">查 询</el-button>
        </div>
      </b-card>

      <b-card class="col-ms-12 search-data" header="查询结果">
        <el-table :data="table.tableData" style="width: 100%" max-height="500">
          <el-table-column prop="cityName" label="区域" width="120">
          </el-table-column>
          <el-table-column prop="year" label="年份" width="100">
          </el-table-column>
          <el-table-column prop="steel" label="报废钢铁产生量（万吨）" width="180">
          </el-table-column>
          <el-table-column prop="copper" label="废铜产生量（万吨）" width="120">
          </el-table-column>
          <el-table-column prop="aluminium" label="废铝产生量（万吨）" width="120">
          </el-table-column>
          <el-table-column prop="lead" label="废铅产生量（万吨）" width="120">
          </el-table-column>
          <el-table-column prop="airConditioner" label="年空调报废量（万台）" width="120">
          </el-table-column>
          <el-table-column prop="washingMachine" label="洗衣机报废量（万台）" width="120">
          </el-table-column>
          <el-table-column prop="tv" label="电视机报废量（万台）" width="120">
          </el-table-column>
          <el-table-column prop="refrigerator" label="电冰箱报废量（万台）" width="120">
          </el-table-column>
          <el-table-column prop="computer" label="微型计算机报废量（万台）" width="120">
          </el-table-column>
<!--          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click.native.prevent="viewDetail(scope.row.id)" type="text" size="small">
                详情
              </el-button>
            </template>
          </el-table-column>-->
        </el-table>
        <el-pagination background layout="total, prev, pager, next" :total="table.total" :page-count="table.pageCount"
                       :page-size="table.pageSizes" :current-page="table.currentPage"
                       @current-change="handleCurrentChange">
        </el-pagination>
      </b-card>
    </el-row>
    <el-dialog title="详情" :visible.sync="view.detailVisible" width="60%" class="detailDialog">
      <el-row :gutter="20">
        <el-col span="6">
          固废名称: {{ this.dialogData.wasteName }}
        </el-col>
        <el-col span="6">
          年份: {{ this.dialogData.year }}
        </el-col>
        <el-col span="6">
          区域: {{ this.dialogData.areaName }}
        </el-col>
        <el-col span="6">
          产生量(吨): {{ this.dialogData.wasteGeneration }}
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
  </div>
</template>
<script>
import  {
  getWasteSourceSelect,
  getWasteBigTypeSelect,
  getWasteSmallTypeSelect,
  getAreaSelect,
} from "@/api/common"
import {
  queryCityMineTableData,
  queryCityMineYear,
  queryCityMineDetail
} from "@/api/waste"

export default {
  name: 'Data',
  props: {
    msg: String
  },
  data() {
    return {
      view: {
        detailVisible: false
      },
      dialogData: {},
      searchModel: {
        searchValue: '',
        sources: [],
        wasteBigTypes: [],
        wasteSmallTypes: [],
        regions: [],
        years: []
      },
      options: {
        wasteSource: [],
        wasteBigType: [],
        wasteSmallType: [],
        area: [],
        years: []
      },
      table: {
        tableData: [],
        total: 0,
        currentPage: 1,
        pageCount: 0,
        pageSizes: 20
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.queryWasteSourceSelect()
      this.queryAreaSelect()
      this.queryYearSelect()
      this.selectData()
    },
    queryAreaSelect() {
      getAreaSelect().then(data => {
        this.options.area = data
      })
    },
    queryYearSelect() {
      queryCityMineYear().then(data => {
        this.options.years = data
      })
    },
    queryWasteSourceSelect() {
      getWasteSourceSelect().then(data => {
        this.options.wasteSource = data
      })
    },
    queryWasteBigSelect() {
      // debugger
      if (this.searchModel.sources.length === 0) return
      getWasteBigTypeSelect(this.searchModel.sources).then(data => {
        this.options.wasteBigType = data
      })
    },
    queryWasteSmallSelect() {
      if (this.searchModel.wasteBigTypes.length === 0) return
      getWasteSmallTypeSelect(this.searchModel.wasteBigTypes).then(data => {
        this.options.wasteSmallType = data
      })
    },
    queryTableData(searchParams, page) {
      if (!searchParams) {
        searchParams = this.searchModel
      }
      queryCityMineTableData(searchParams, page).then(data => {
        this.table.tableData = data.records
        this.table.total = data.total
        this.table.currentPage = data.current
        this.table.pageSizes = data.size
        this.table.pageCount = data.pages
      })
    },
    selectData() {
      this.queryTableData()
    },
    handleCurrentChange(val) {
      const page = {
        pageNo: val,
        pageSize: this.table.pageSizes
      }
      this.queryTableData(this.searchModel, page)
    },
    viewDetail(id) {
      // debugger
      if (id === null || id === undefined || id === '') {
        return
      }
      queryCityMineDetail(id).then(data => {
        this.dialogData = data
        this.view.detailVisible = true
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@import "../../assets/less/data.less";
</style>
